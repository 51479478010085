.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    height: 3rem;
    width: 10rem;
}

.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}

.header-menu>li:hover{
    cursor:pointer;
    color:var(--orange)
}

.bars-container{
    background-color: var(--appColor);
    padding: 0.5rem;
    border-radius: 5px;
}
.bars{
    width: 1.2rem;
    height: 1rem;
}

@media screen and (max-width:768px){
    .logo{
        height: 2.5rem;
        width: 8rem;
    }

    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 9;
    }

    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 1.5rem;
        border-radius: 10px;
    }
}