.Plans{
    margin-top: -4rem;
    padding: 0.2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.programs-header{
    gap: 2rem;
}

.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

.plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    border-radius: 20px;
    color: white;
    gap: 1.3rem;
    padding: 2rem;
    justify-content: center;
    align-items: center;
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan:nth-child(2)>svg{
    transform: scale(1.4);
}
.plan>svg{
    fill:var(--orange);
    width: 2rem;
    height: 2rem;
    transform: scale(1.9);
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    margin-right: auto;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
    margin-right: auto;
}
.pricing{
    margin-right: auto;
}
.pricing>:nth-child(1){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    display: flex;
    font-size: 0.8rem;
    gap: 1rem;
    margin-right: auto;
}
.plan>:nth-child(5)>img{
    width: 1rem;
}
.plan>:nth-child(6){
    justify-content: center;
}
.plan>:nth-child(6)>.btn{
    justify-content: center;
    width: 15rem;
}

.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}
.plans>:nth-child(2)>svg{
    fill: white;
}
.plans>:nth-child(2)>:nth-child(6)>.btn{
    color: orange;
}

.plans-blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}
.plans-blur-2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}

@media screen and (max-width:768px){
    .programs-header{
        margin-bottom: 0;
    }
    .Plans{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .plans{
        flex-direction: column;
        gap: 2rem;
    }
    .plan{
        gap: 1rem;
        padding: 1.5rem;
    }
    .plans>:nth-child(2){
        transform: none;
    }
    .plan>:nth-child(6)>.btn{
        justify-content: center;
        width: 14rem;
    }
}