.Footer-container{
    margin-top: 0;
    position: relative;
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links{
    display: flex;
    gap: 4rem;
}
.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f{
    transform: scale(0.5);
}

.blur-f-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}
.blur-f-2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background:rgb(255,115,0);
}

@media screen and (max-width:768px) {
    .footer{
        padding: 0;
        gap: 2rem;
        height: 12rem;
    }

    .social-links{
        margin-top: 3rem;
        margin-bottom: 0rem;
        gap: 2rem;
    }
    .social-links>img{
        width: 1.5rem;
        height: 1.5rem;
    }
    .blur-f-2{
        background: none;
    }
}